import React, { useEffect, useState } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import {
  confirmToken,
  queryParams,
  regenerateToken
} from "../../lib/actions.js"

export function VerifyAccount() {
  const [confirmationToken, setConfirmationToken] = useState(null)
  const [email, setEmail] = useState("")
  const [buttonDisabled, setButtonDisabled] = useState(null)
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState("Token verification did not work. Try again later.")

  useEffect(() => {
    const params = queryParams()
    const token = params["confirmation_token"]
    const emailText = params["email"]
    // http://cordialcredit.org/confirmation?confirmation_token=9fPHHeZpPb5qifnW8sQV
    // There are 2 use cases:
    // 1) being called from the sign up page - just rendering the text (we get email param)
    // 2) link clicked in the email - rendering page with info
    //    about upcoming redirection (we get confirmation_token param)
    //    and fetching the token from the backend and redirecting to "/"
    if (token) {
      setConfirmationToken(token)
      const onDone = () => {
        setLoading(false)
      }
      const onValidationError = (error) => {
        setErrorMessage("Email " + error.email[0])
      }
      confirmToken({ token, onValidationError, onDone })
      setButtonDisabled(true)
    }
    if (emailText) {
      setEmail(emailText)
    }
  }, [])

  useEffect(() => {
    if (buttonDisabled) {
      const timeout = setTimeout(() => {
        setButtonDisabled(false)
      }, 2000)
      return () => clearTimeout(timeout)
    }
  }, [buttonDisabled])

  const clickResend = (event) => {
    setButtonDisabled(true)
    event.preventDefault()
    regenerateToken({ email, setButtonDisabled })
  }
  return (
    <Box
      sx={{
        marginTop: 8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: 3
      }}
    >
      <img style={{ margin: 8, width: "50px", height: "50px" }} src="/cordial_credit_black_small.png" />
      <Typography component="h1" variant="h5">
        Account Verification
      </Typography>
      { confirmationToken ? (
        <Box sx={{ my: 12 }}>
          { loading ? (
            <CircularProgress />
          ) : (
            <Typography>
              {errorMessage}
            </Typography>
          ) }
        </Box>
      ) : (
        <Box component="form" noValidate sx={{ mt: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography component="h2" variant="h6">
                Check your email
              </Typography>
            </Grid>
            <Grid item xs={12} sx={{ mb: 4 }}>
              To verify your account, go to your mailbox and click the link
              in the email from hello@cordialcredit.org.
              If you didn't get the email, please check the spam folder.
            </Grid>
            <Grid item xs={12}>
              If the email didn't arrive, try resending it.
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                value={email}
                onChange={(evt) => setEmail(evt.target.value)}
                fullWidth
                id="login"
                label="Email Address"
                name="login"
                autoComplete="email"
                autoFocus
                inputProps={{readOnly: "readonly"}}
              />
            </Grid>

          </Grid>
          <Grid sx={{ position: "relative" }}>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              onClick={clickResend}
              sx={{ mt: 3, mb: 2 }}
              disabled={buttonDisabled || loading}
            >
              Resend the email
            </Button>
            {loading && (
              <CircularProgress
                size={24}
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  marginTop: '-8px',
                  marginLeft: '-8px',
                }}
              />
            )}
          </Grid>
        </Box>
      ) }
    </Box>
  );
}
