import React, { useState, useEffect } from 'react';
import {
  Grid,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  Alert
} from '@mui/material';
import EditSharpIcon from '@mui/icons-material/EditSharp'
import { SimplyIconButton } from '../simplyComponents/SimplyIconButton'
import { useSelector } from '../../store'
import { SimplyLink } from '../simplyComponents/SimplyLink';
import { CustomSlider } from '../shared/CustomSlider';
import { centsAsCurrency } from '../../lib/utils'

const SORTING = {
  date: { field: "userCreatedAt", direction: 1 },
  budget: { field: "value", direction: -1 },
  alphabetically: { field: "name", direction: 1 }
}

const attrBy = (record, sorting, sliders) => {
  if (sorting === "date") {
    return sliders[record.uuid][SORTING["date"].field]
  }
  if (sorting === "budget") {
    return sliders[record.uuid][SORTING["budget"].field]
  }
  // sorting === "alphabetically"
  return record[SORTING["alphabetically"].field]
}

const sortBy = (sorting, nonprofits, sliders) => {
  const newNonprofits = Array.from(nonprofits);
  const slidersByUuids = []
  sliders.forEach((slider) => {
    slidersByUuids[slider.nonprofitUuid] = slider
  })
  return newNonprofits.sort((a, b) => {
    const valA = attrBy(a, sorting, slidersByUuids)
    const valB = attrBy(b, sorting, slidersByUuids)
    if (valA < valB) {
      return -1 * SORTING[sorting].direction
    }
    if (valA > valB) {
      return 1 * SORTING[sorting].direction
    }
    return 0
  })
}

export function Subscriptions({ setSubtitle }) {
  const [sorting, setSorting] = useState("date")
  const [sortedNonprofits, setSortedNonprofits] = useState([])
  const [shortlyAfterLoad, setShortlyAfterLoad] = useState(false)
  const budget = useSelector((state) => state.dashboard.actualBudget)
  const nonprofits = useSelector((state) => state.dashboard.nonprofits)
  const proposals = useSelector((state) => state.dashboard.proposals)
  const sliders = useSelector((state) => state.dashboard.sliders)
  const slidersFetched = useSelector((state) => state.dashboard.fetched)
  useEffect(() => {
    const nonprofitsCount = nonprofits.length + proposals.length
    setSubtitle({ dashboard: `${nonprofitsCount} nonprofits` })
  }, [nonprofits.length, proposals.length])
  const resort = () => {
    setSortedNonprofits(sortBy(sorting, nonprofits, sliders))
  }
  useEffect(() => {
    resort()
  }, [nonprofits, sliders, sorting])
  useEffect(() => {
    setTimeout(() => {
      setShortlyAfterLoad(true)
    }, 2000)
  }, [])

  const handleSortingChange = (event) => {
    setSorting(event.target.value)
  }

  if (!slidersFetched) {
    return ""
  }

  return (
    <Grid container sx={{p: 0}}>
      <Grid item xs={12}>
        <Grid container justifyContent="space-between" alignItems="flex-end" sx={{mb: 1, px: 2 }}>
          <Grid item>
            <FormControl variant="standard">
              <InputLabel id="demo-simple-select-label">Sort by</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={(sorting === "") ? "date" : sorting}
                label="Sort by"
                onChange={handleSortingChange}
              >
                <MenuItem value={"date"}>Date added</MenuItem>
                <MenuItem value={"budget"}>Budget</MenuItem>
                <MenuItem value={"alphabetically"}>Name</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs>
            { budget ? (
              <Typography sx={{ float: "right", display: "flex", alignItems: "end", pb: "4px" }}>
                <Box
                  component="span"
                  sx={{ display: { xs: "none", md: "inline" } }}
                >
                  Your monthly budget:
                </Box>{'\u00A0'}
                <Box
                  component="span"
                  sx={{ display: { xs: "none", sm: "inline", md: "none" } }}
                >
                  Budget:{'\u00A0'}
                </Box>
                <Box
                  component="span"
                  sx={{ display: { xs: "none", sm: "inline" } }}
                >
                  (<SimplyLink to="/budget">change</SimplyLink>)
                </Box>{'\u00A0'}
                <Box
                  component="span"
                  sx={{ display: { xs: "inline-flex", sm: "none" } }}
                >
                  {'\u00A0'}
                  <SimplyIconButton to="/budget" aria-label="edit budget" color="primary" size="small" sx={{ display: { xs: "inline-flex", sm: "none" }}}>
                    <EditSharpIcon fontSize="small"/>
                  </SimplyIconButton>
                </Box>
                <span style={{fontWeight: "bold"}}>
                  {centsAsCurrency(budget)}
                </span>
              </Typography>
            ) : (
              <Typography sx={{ float: "right", display: "flex", alignItems: "end", pb: "4px" }}>
                <SimplyLink to="/budget">Set your monthly budget</SimplyLink>
              </Typography>
            )}

          </Grid>
        </Grid>

        { shortlyAfterLoad && (sortedNonprofits.length === 0) ? (
          <Grid container justifyContent="space-between" alignItems="flex-end" sx={{mb: 1, px: 2 }}>
            <Alert severity="info"  sx={{ my: 2 }}>
              It looks like you haven't chosen your favorite nonprofits yet. <SimplyLink to="/search">Check out</SimplyLink> the list
              of nonprofits that we already support and move the slider for the ones you like as much to the right as much you like them. If your favorite ones are not there,
              don't worry, we keep adding new ones. In the meantime, feel free
              to <SimplyLink to="/add">let us know</SimplyLink> about the nonprofits you love.
              Have in mind that as a registered nonprofit,
              we have to (and we very much want to) verify every nonprofit we cooperate with,
              so we ask you for a bit of patience :-)
            </Alert>
          </Grid>
        ) : (
          sortedNonprofits.map((nonprofit) => <CustomSlider
            key={nonprofit.uuid}
            nonprofit={nonprofit}
            resort={resort}
          />)
        )}

      </Grid>
    </Grid>
  )
}
