export const calculateRates = (sliders, budgetInCents) => {
  const actualBudget = calculateActualBudgetInCents(budgetInCents)

  const slidersSum = sliders.reduce(valueSum, 0)
  let split = sliders.map((slider) => {
    const value = valueOf(slider)
    const valueAsCurrencyInCents = Math.round((1.0 * actualBudget * value) / slidersSum)
    const valueAsCurrency = valueAsCurrencyInCents / 100.0
    return {
      sliderUuid: slider.uuid,
      valueInCents: valueAsCurrencyInCents
    }
  })
  const actualSum = split.reduce((acc, item) => acc + item.valueInCents, 0)
  const error = actualBudget - actualSum

  if ((error !== 0) && (split.length > 0)) {
    const lastNonEmptySlider = split.find((slider) => slider.valueInCents > 0)
    if (lastNonEmptySlider) {
      lastNonEmptySlider.valueInCents = lastNonEmptySlider.valueInCents + error
    }
  }
  return split
}

export const calculateFeeInCents = (budgetInCents) => {
  return budgetInCents ? Math.floor(budgetInCents * 0.05 + 50) : 0
}

export const calculateActualBudgetInCents = (budgetInCents) => {
  const feeAmount = calculateFeeInCents(budgetInCents)
  return budgetInCents - feeAmount
}

const valueOf = (item) => (typeof item.newValue !== 'undefined') ? item.newValue : item.value
const valueSum = (acc, item) => acc + valueOf(item)
