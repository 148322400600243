import React from 'react';
import {
  logOut
} from '../../lib/actions'
import { SimplyDefaultLayout } from '../simplyComponents/SimplyDefaultLayout'

export const DefaultLayout = ({ children, sections, subtitle }) => {
  return (
    <SimplyDefaultLayout
      children={children}
      sections={sections}
      subtitle={subtitle}
      logOut={logOut}
      url="https://cordialcredit.org/"
      title="Cordial Credit"
      themeOverwrites={{}}
      transparentHeader={true}
    />
  )
}
