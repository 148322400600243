import React, { useState } from 'react';
import { useSearchParams, useNavigate } from "react-router-dom";
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import { SimplyLink } from '../simplyComponents/SimplyLink';
import {
  CircularProgress,
  Link,
  FormControlLabel,
  FormGroup,
  Checkbox,
  FormControl,
  FormHelperText
} from '@mui/material'
import {
  subscribe,
  signUp
} from "../../lib/actions.js"

function Subscribe({ accountVerification, setAccountVerification }) {
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState(null)
  const [success, setSuccess] = useState(false)
  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true)
    const data = new FormData(event.currentTarget);
    const params = {
      email: data.get("email")
    }
    if (params.email === "") {
      setErrors({email: ["To let you know, we will need your email address :-)"]})
      return
    }
    const onSubscribeSuccess = () => {
      setSuccess(true)
    }
    const onSubscribeValidationError = (err) => {
      setErrors(err.response.data.errors)
    }
    const onDone = () => {
      setLoading(false)
    }
    subscribe({ params, onSubscribeSuccess, onSubscribeValidationError, onDone })
  };

  const hasError = (field) => {
    return errors && errors[field]
  }
  const errorText = (field) => {
    return errors && errors[field] && errors[field].join(", ")
  }

  return (
    <Box
      sx={{
        marginTop: 7,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: 3,
        paddingBottom: 2
      }}
    >
      <img style={{ margin: 8, width: "50px", height: "50px" }} src="/cordial_credit_black_small.png" />
      <Typography component="h1" variant="h5">
        Sign up (coming soon)
      </Typography>
      { success ? (
        <Alert>
          <Typography>
            Thank you! We will let you know as soon as we're ready.
          </Typography>
        </Alert>
      ) : (
        <Alert severity="warning" sx={{mt: 2}}>
          <Typography>
            The service is currently in an invite-only beta version. We will open it to everyone soon.
          </Typography>
          <Typography sx={{ mt: 2 }}>
            If you give us your email address, we will let you know as soon as it's open.
          </Typography>
        </Alert>
      )}
      <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormGroup>
              <TextField
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                error={hasError("email")}
                helperText={errorText("email")}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                disabled={loading}
                sx={{ mt: 3, mb: 1 }}
              >
                Let me know when it's live
              </Button>
            </FormGroup>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export function RealSignup() {
  const [searchParams, setSearchParams] = useSearchParams()
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState(null)
  const navigate = useNavigate()

  const redirectToVerifyAccount = (params) => {
    navigate(`/confirmation?email=${encodeURIComponent(params.user.email)}`)
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true)
    const data = new FormData(event.currentTarget);
    const params = {
      user: {
        email: data.get("login"),
        password: data.get("password"),
        name: data.get("firstName")
      }
    }
    if (searchParams.get('nonprofit')) {
      params.slider = {
        id: searchParams.get('nonprofit'),
        value: searchParams.get('value')
      }
    }
    if (!data.get("consent")) {
      setErrors({consent: ["You need to accept terms and conditions."]})
      setLoading(false)
      return;
    }
    const onDone = () => {
      setLoading(false)
    }
    const onValidationError = (err) => {
      setErrors(err.response.data.errors)
    }
    signUp({ params, redirectToVerifyAccount, onValidationError, onDone })
  };

  const hasError = (field) => {
    return !!(errors && errors[field])
  }
  const errorText = (field) => {
    return hasError(field) && errors[field].join(", ")
  }

  const termsAndConditionsLine = <span>
    I accept <Link target="_blank" href="https://cordialcredit.org/terms-of-use">terms and conditions</Link>
  </span>

  const loginUrl = searchParams.get('nonprofit') ? `/login?value=${searchParams.get('value')}&nonprofit=${searchParams.get('nonprofit')}`: "/login"

  return (
    <Box
      sx={{
        marginTop: 7,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: 3,
        paddingBottom: 2
      }}
    >
      <img style={{ margin: 8, width: "50px", height: "50px" }} src="/cordial_credit_black_small.png" />
      <Typography component="h1" variant="h5">
        Sign up
      </Typography>
      <Typography>
        No credit card required.
      </Typography>
      <Typography>
        Already have an account? <SimplyLink to={loginUrl}>Sign in!</SimplyLink>
      </Typography>
      <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
        <TextField
          margin="normal"
          required
          fullWidth
          id="login"
          label="Email Address"
          name="login"
          autoComplete="email"
          autoFocus
          error={hasError("email")}
          helperText={errorText("email")}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          name="password"
          label="Password"
          type="password"
          id="password"
          autoComplete="new-password"
          error={hasError("password")}
          helperText={errorText("password")}
        />
        <TextField
          margin="normal"
          autoComplete="fname"
          name="firstName"
          fullWidth
          id="firstName"
          label="First Name (how you'd like us to call you)"
        />
        <FormControl
          required
          error={hasError("consent")}
        >
          <FormGroup>
            <FormControlLabel control={<Checkbox id="consent" name="consent"/>} label={termsAndConditionsLine} />
          </FormGroup>
          { hasError("consent") ? (
            <FormHelperText>{errorText("consent")}</FormHelperText>
          ) : ""}
        </FormControl>
        <Grid sx={{ position: "relative" }}>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            disabled={loading}
            sx={{ mt: 1, mb: 2 }}
          >
            Sign Up
          </Button>
          {loading && (
            <CircularProgress
              size={24}
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                marginTop: '-8px',
                marginLeft: '-8px',
              }}
            />
          )}
        </Grid>
      </Box>
    </Box>
  );
}

export function Signup() {
  return RealSignup()
}