import React, { useState } from 'react';
import { useSearchParams } from "react-router-dom";
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { SimplyLink } from '../simplyComponents/SimplyLink';
import {
  logIn
} from "../../lib/actions.js"

export function Login() {
  const [searchParams, setSearchParams] = useSearchParams()
  const [loading, setLoading] = useState(false)
  const [validationError, setValidationError] = useState(null)
  const onValidationError = (err) => {
    setValidationError(err.response.data.error)
  }
  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true)
    const onDone = () => setLoading(false)
    const data = new FormData(event.currentTarget);
    const params = {
      user: {
        email: data.get('login'),
        password: data.get('password')
      }
    }
    if (searchParams.get('nonprofit')) {
      params.slider = {
        id: searchParams.get('nonprofit'),
        value: searchParams.get('value')
      }
      console.log('kher')
    }
    console.log(params, searchParams)
    logIn({ params, onValidationError, onDone })
  };

  const signupUrl = searchParams.get('nonprofit') ? `/signup?value=${searchParams.get('value')}&nonprofit=${searchParams.get('nonprofit')}`: "/signup"

  return (
    <Box
      sx={{
        marginTop: 7,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: 3
      }}
    >
      <img style={{ margin: 8, width: "50px", height: "50px" }} src="/cordial_credit_black_small.png" />
      <Typography component="h1" variant="h5">
        Sign in
      </Typography>
      <Typography>
      Don't have an account? <SimplyLink to={signupUrl}>Sign up!</SimplyLink>
      </Typography>
      <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
        <TextField
          margin="normal"
          required
          fullWidth
          id="login"
          label="Email Address"
          name="login"
          autoComplete="email"
          autoFocus
          error={validationError}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          name="password"
          label="Password"
          type="password"
          id="password"
          autoComplete="current-password"
          error={validationError}
          helperText={validationError}
        />
        <Grid sx={{ position: "relative" }}>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 2, mb: 2 }}
            disabled={loading}
          >
            Sign In
          </Button>
          {loading && (
            <CircularProgress
              size={24}
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                marginTop: '-8px',
                marginLeft: '-8px',
              }}
            />
          )}
        </Grid>
      </Box>
    </Box>
  );
}
