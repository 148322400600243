import { nodeEnv } from "./envVars"
import {
  BACKEND_URL_PROD,
  BACKEND_URL_DEV,
  FRONTEND_URL_PROD,
  FRONTEND_URL_DEV,
  STRIPE_PUBLIC_KEY_PROD,
  STRIPE_PUBLIC_KEY_DEV
} from "./constants"

export const backendUrl = () => {
  return (nodeEnv() === "production") ? BACKEND_URL_PROD : BACKEND_URL_DEV
}

export const frontendUrl = () => {
  return (nodeEnv() === "production") ? FRONTEND_URL_PROD : FRONTEND_URL_DEV
}

export const stripePublicKey = () => {
  return (nodeEnv() === "production") ? STRIPE_PUBLIC_KEY_PROD : STRIPE_PUBLIC_KEY_DEV
}
