import * as React from 'react';
import { useLocation, Link } from 'react-router-dom'
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

const renderItem = ({ key, section, onClick, selected }) => {
  return (
    <Link key={key} to={section.path} onClick={onClick} style={{ textDecoration: "none", color: "inherit" }}>
      <ListItem button selected={selected(section.path)}>
        <ListItemIcon>
          { section.menuIcon }
        </ListItemIcon>
        <ListItemText primary={ section.title } />
      </ListItem>
    </Link>
  )
}

export const SimplyLeftMenu = ({ onClick, sections }) => {
  const location = useLocation()

  const selected = (path) => {
    return location.pathname === path
  }
  const keys = Object.keys(sections).filter((key) => sections[key].menuIcon )
  return (
    <div>
      { keys.map((key) => renderItem({ key, section: sections[key], onClick, selected })) }
    </div>
  )
};
