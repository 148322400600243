import React, { useState, useEffect } from 'react';
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import useMediaQuery from '@mui/material/useMediaQuery';
import { CheckoutForm } from './CheckoutForm';
import {
  fetchOrCreateSubscription
} from "../../lib/actions.js"
import { stripePublicKey } from "../../lib/features.js"

const stripePromise = loadStripe(stripePublicKey());

export function StripePayment() {
  const isDarkModeEnabled = useMediaQuery('(prefers-color-scheme: dark)');
  const [clientSecret, setClientSecret] = useState()
  const stripeTheme = isDarkModeEnabled ? "night" : "stripe"

  useEffect(() => {
    fetchOrCreateSubscription({ setClientSecret })
  }, [])

  return (
    clientSecret ? (
      <Elements options={{ clientSecret, appearance: { theme: stripeTheme, variables: {fontFamily: "sans-serif"}}}} stripe={stripePromise}>
        <CheckoutForm clientSecret={clientSecret} />
      </Elements>
    ) : "Loading Stripe..."
  )
}
