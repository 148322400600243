import {
  callLogin,
  callLogout,
  callSignup,
  callAccountGet,
  callConfirmToken,
  callRegenerateToken,
  callNonprofitsGet,
  callNonprofitGet,
  callSlidersGet,
  callSliderPost,
  callSliderPut,
  callSliderDelete,
  callBudgetPost,
  callBudgetPut,
  callCheckoutData,
  callBillingData,
  callProposalPost,
  callPaymentConfirmationPendingPost,
  callSubscribePost,
  callPaymentsGet
} from "./apis.js"
import { dispatch, getState } from '../store'

const AUTH_TOKEN_KEY = "authToken"
const GENERAL_ERROR_MESSAGE = "Something went wrong. Please try again in a moment."
const RELOAD_ERROR_MESSAGE = "Something went wrong. Please reload the page in a moment."
const PROPOSAL_SUCCESS_MESSAGE = "Thank you for proposing a new nonprofit. We will let you know as soon as we start accepting donations for them."
const BUDGET_CREATED_MESSAGE = "Your budget was successfully set!"
const BUDGET_UPDATED_MESSAGE = "Your subscription was successfully updated!"

const defaultOnErrorWithMessage = (err, afterCallback) => {
  defaultOnError(err) || dispatch.notifications.set({ severity: "error", text: GENERAL_ERROR_MESSAGE })
  if (afterCallback) {
    afterCallback()
  }
}

const defaultOnError = (err) => {
  console.log("ERROR", err)
  return deleteAuthTokenIfUnauthorized(err)
}

const deleteAuthTokenIfUnauthorized = (err) => {
  if (err.response && (err.response.status === 401)) {
    dispatch.authToken.reset()
    window.localStorage.setItem(AUTH_TOKEN_KEY, null)
    return true
  }
}

export const setNewSliderValue = ({ uuid, value }) => {
  dispatch.dashboard.setNewValue({ uuid, value })
}

export const retrieveTokenFromLocalStorage = () => {
  const token = window.localStorage.getItem(AUTH_TOKEN_KEY)
  // Devise JWT returns "null" as string sometimes
  const actualToken = (token === "null") ? null : token
  dispatch.authToken.set(actualToken)
}

export const silenceAlert = () => {
  dispatch.notifications.reset()
}

export const queryParams = () => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  return Object.fromEntries(urlSearchParams.entries());
}

export const logIn = ({ params, onValidationError, onDone }) => {
  const onError = (err) => {
    onDone()
    if (err.response && (err.response.status === 401)) {
      onValidationError(err)
    } else {
      dispatch.notifications.set({ severity: "error", text: GENERAL_ERROR_MESSAGE })
      console.log("ERROR", err)
    }
  }
  const onSuccess = (response) => {
    onDone()
    const authToken = response.headers.authorization
    if (authToken) {
      window.localStorage.setItem(AUTH_TOKEN_KEY, authToken)
      dispatch.authToken.set(authToken)
    }
  }
  callLogin(params, onSuccess, onError)
}

export const confirmToken = ({ token, onValidationError, onDone }) => {
  const onError = (err) => {
    onDone()
    if (err.response && (err.response.status === 422)) {
      onValidationError(err.response.data)
    } else {
      dispatch.notifications.set({ severity: "error", text: RELOAD_ERROR_MESSAGE })
      console.log("ERROR", err)
    }
  }
  const onSuccess = (response) => {
    onDone()
    const authToken = response.headers.authorization
    window.localStorage.setItem(AUTH_TOKEN_KEY, authToken)
    dispatch.authToken.set(authToken)
    if (response.data.notification) {
      dispatch.notifications.set(response.data.notification)
    }
  }
  const params = { confirmation_token: token }
  callConfirmToken(params, onSuccess, onError)
}

export const regenerateToken = ({ email, setButtonDisabled }) => {
  const onError = (err) => {
    console.log("ERROR", err)
    dispatch.notifications.set({ severity: "error", text: GENERAL_ERROR_MESSAGE })
  }
  const onSuccess = (response) => {
    setButtonDisabled(false)
  }
  callRegenerateToken({user: { email }}, onSuccess, onError)
}

export const signUp = ({ params, redirectToVerifyAccount, onValidationError, onDone }) => {
  const onError = (err) => {
    if (err.response && (err.response.status === 422)) {
      onValidationError(err)
    } else {
      console.log("ERROR", err)
      dispatch.notifications.set({ severity: "error", text: GENERAL_ERROR_MESSAGE })
    }
    onDone()
  }
  const onSuccess = (response) => {
    console.log("Setting email", params.user.email)
    redirectToVerifyAccount(params)
    // We don't call onDone because at this point the component
    // will be unmounted (as the redirection to login page is ongoing)
    // onDone()
  }
  callSignup(params, onSuccess, onError)
}

export const createBudget = ({ params, setErrors, setAccount, onDone }) => {
  const state = getState()
  const authToken = state.authToken.value
  const onError = (err) => {
    onDone()
    if (err.response && (err.response.status === 422)) {
      setErrors(err.response.data.errors)
    } else {
      setErrors(null)
      dispatch.notifications.set()
      dispatch.notifications.set({ severity: "error", text: GENERAL_ERROR_MESSAGE })
      console.log("ERROR", err)
    }
  }
  const onSuccess = (response) => {
    const result = response.data.result
    onDone()
    setErrors(null)
    dispatch.dashboard.updateBudget(result.budget)
    dispatch.account.set(result.account)
  }
  callBudgetPost(authToken, params, onSuccess, onError)
}

export const updateBudget = ({ params, setErrors, onDone, onSuccess }) => {
  const state = getState()
  const authToken = state.authToken.value
  const onError = (err) => {
    onDone()
    if (err.response && (err.response.status === 422)) {
      setErrors(err.response.data.errors)
    } else {
      setErrors(null)
      dispatch.notifications.set({ severity: "error", text: GENERAL_ERROR_MESSAGE, action: "goToDashboard" })
      console.log("ERROR", err)
    }
  }
  const onSuccessCallback = (response) => {
    const result = response.data.result
    onDone()
    onSuccess()
    setErrors(null)
    dispatch.dashboard.updateBudget(result.budget)
    dispatch.account.set(result.account)
    dispatch.notifications.set({ severity: "success", text: BUDGET_UPDATED_MESSAGE, action: "goToDashboard" })
  }
  callBudgetPut(authToken, params, onSuccessCallback, onError)
}

export const fetchAccount = () => {
  const state = getState()
  const authToken = state.authToken.value
  if (!authToken) {
    return
  }
  const onError = (err) => defaultOnErrorWithMessage(err)
  const onSuccess = (response) => {
    const result = response.data.result
    dispatch.account.set(result.account)
  }
  callAccountGet(authToken, {}, onSuccess, onError)
}

export const logOut = () => {
  const state = getState()
  const authToken = state.authToken.value
  const onSuccess = () => {
    window.localStorage.removeItem(AUTH_TOKEN_KEY)
    dispatch.authToken.reset()
  }
  const onError = (err) => {
    console.log("ERROR", err)
  }
  callLogout(authToken, onSuccess, onError)
}

export const fetchNonprofits = ({ setNonprofits, search, tags }) => {
  const params = { search, tags }
  const onError = (err) =>  defaultOnErrorWithMessage(err)
  const onSuccess = (response) => {
    const result = response.data.result
    setNonprofits(result.nonprofits)
  }
  callNonprofitsGet(params, onSuccess, onError)
}

export const fetchNonprofit = ({ nonprofitId, setNonprofit }) => {
  const params = { id: nonprofitId }
  const onError = (err) =>  defaultOnErrorWithMessage(err)
  const onSuccess = (response) => {
    const result = response.data.result
    setNonprofit(result.nonprofit)
  }
  callNonprofitGet(params, onSuccess, onError)
}

export const fetchPayments = () => {
  const state = getState()
  const authToken = state.authToken.value
  if (!authToken) {
    return
  }
  const params = {}
  const onError = (err) =>  defaultOnErrorWithMessage(err)
  const onSuccess = (response) => {
    const result = response.data.result
    dispatch.payments.set(result.payments)
  }
  callPaymentsGet(authToken, params, onSuccess, onError)
}

export const fetchSliders = () => {
  const state = getState()
  const authToken = state.authToken.value
  if (!authToken) {
    return
  }
  const params = {}
  const onError = (err) =>  defaultOnErrorWithMessage(err)
  const onSuccess = (response) => {
    const result = response.data.result
    dispatch.dashboard.set(result)
  }
  callSlidersGet(authToken, params, onSuccess, onError)
}

export const createSlider = ({ nonprofitUuid, value }) => {
  const state = getState()
  const authToken = state.authToken.value
  const params = {
    nonprofit_uuid: nonprofitUuid,
    slider : {
      value
    }
  }
  const onError = (err) =>  defaultOnErrorWithMessage(err)
  const onSuccess = (response) => {
    const result = response.data.result
    dispatch.dashboard.set(result)
  }
  callSliderPost(authToken, params, onSuccess, onError)
}

export const updateSlider = ({ uuid, value }) => {
  const state = getState()
  const authToken = state.authToken.value
  const params = { value }
  const onError = (err) =>  defaultOnErrorWithMessage(err)
  const onSuccess = (response) => {
    const result = response.data.result
    dispatch.dashboard.set(result)
  }
  callSliderPut(authToken, uuid, params, onSuccess, onError)
}

export const deleteSlider = ({ uuid }) => {
  const state = getState()
  const authToken = state.authToken.value
  const onError = (err) =>  defaultOnErrorWithMessage(err)
  const onSuccess = (response) => {
    const result = response.data.result
    dispatch.dashboard.set(result)
  }
  callSliderDelete(authToken, uuid, onSuccess, onError)
}

export const fetchOrCreateSubscription = ({ setClientSecret }) => {
  const state = getState()
  const authToken = state.authToken.value
  const onError = (err) => defaultOnErrorWithMessage(err)
  const onSuccess = (response) => {
    const result = response.data.result
    setClientSecret(result.clientSecret)
  }
  callCheckoutData(authToken, {}, onSuccess, onError)
}

export const fetchBillingData = ({ redirect }) => {
  const state = getState()
  const authToken = state.authToken.value
  const onError = (err) => defaultOnErrorWithMessage(err)
  const onSuccess = (response) => {
    const result = response.data.result
    redirect(result.stripeUrl)
  }
  callBillingData(authToken, {}, onSuccess, onError)
}

export const createProposal = ({ params, onDone }) => {
  const state = getState()
  const authToken = state.authToken.value
  const onError = (err) =>  defaultOnErrorWithMessage(err, onDone)
  const onSuccess = () => {
    onDone()
    dispatch.notifications.set({ severity: "success", text: PROPOSAL_SUCCESS_MESSAGE })
  }
  callProposalPost(authToken, params, onSuccess, onError)
}

export const setPaymentConfirmationPending = () => {
  const state = getState()
  const authToken = state.authToken.value
  const onError = (err) =>  defaultOnErrorWithMessage(err)
  const onSuccess = (response) => {
    const result = response.data.result
    dispatch.account.set(result.account)
    dispatch.notifications.set({ severity: "success", text: BUDGET_CREATED_MESSAGE, action: "goToDashboard" })
  }
  callPaymentConfirmationPendingPost(authToken, {}, onSuccess, onError)
}

export const subscribe = ({ params, onSubscribeSuccess, onSubscribeValidationError, onDone }) => {
  const onError = (err) => {
    if (err.response && (err.response.status === 422)) {
      onSubscribeValidationError(err)
    } else {
      dispatch.notifications.set({ severity: "error", text: GENERAL_ERROR_MESSAGE })
      console.log("ERROR", err)
    }
  }
  const onSuccess = (response) => {
    console.log("Subscribed", params.email)
    onSubscribeSuccess(true)
  }
  callSubscribePost({user: params}, onSuccess, onError)
}
