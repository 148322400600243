import React, { useState } from 'react';
import {
  Grid,
  Paper,
  Typography,
  FormControl,
  Button,
  TextField
} from '@mui/material';
import { createProposal } from "../../lib/actions";

export function Add() {
  const [loading, setLoading] = useState(false)
  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true)
    const onDone = () => setLoading(false)
    const data = new FormData(event.currentTarget);
    const params = {
      proposal: {
        name: data.get('name'),
        url: data.get('url'),
        reasoning: data.get('reasoning')
      }
    }
    createProposal({ params, onDone })
  }
  return (
    <Grid container spacing={2} px={2}>
      <Grid item xs={12}>
        <Typography sx={{ mb: 2 }}>
          Tell us about the nonprofit you would like to see on this platform. We contact most of the nonprofits our users suggest, but we prioritise the ones that have the biggest impact on making the world a better place.
        </Typography>
        <Paper component="form" onSubmit={handleSubmit} sx={{ margin: "auto" , display: 'flex', flexDirection: 'column', boxShadow: "none" }}>
          <FormControl>
            <TextField
              required
              id="name"
              name="name"
              label="Name of the nonprofit"
              helperText="What's the name of the nonprofit? This helps us find it."
              sx={{ mb: 2 }}
            />
            <TextField
              id="url"
              name="url"
              label="Website of this nonprofit"
              helperText="Does it have a website? If it does, please leave the address here."
              sx={{ mb: 2 }}
            />
            <TextField
              id="reasoning"
              name="reasoning"
              label="What would you like the world to change, and how do they  drive that change?"
              helperText="At Cordial Credit we care about saving the world together. Please share with us why you think that this nonprofit is saving the world."
              multiline
              rows={4}
              sx={{ mb: 2 }}
            />
            <Button
              type="submit"
              variant="contained"
              sx={{ mt: 2 }}
              disabled={loading}
            >
              Add
            </Button>
          </FormControl>
        </Paper>
      </Grid>
    </Grid>
  )
}
