import React from "react"
import { useLocation, Outlet, Navigate } from "react-router-dom";

export const Root = ({ authToken, sections }) => {
  const location = useLocation()

  // Waiting for fetching the token from local storage
  if (authToken === false) {
    return ""
  }

  const pathIs = (path) => {
    return location.pathname === path
  }

  const pathIsAny = (paths) => {
    return paths.some((path) => pathIs(path))
  }

  if (authToken) {
    if (pathIsAny([sections.login.path, sections.signup.path, sections.confirmation.path])) {
      return <Navigate to={sections.dashboard.path} />
    }
  } else {
    if (!pathIsAny([sections.login.path, sections.signup.path, sections.confirmation.path, sections.search.path])) {
      return <Navigate to={sections.login.path} />
    }
  }

  return (
    <Outlet />
  );
}
