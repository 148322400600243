import axios from 'axios';

const defaultHeaders = () => {
  return {
    "Content-Type": "application/json"
  }
}

let baseUrl = undefined

export const setupApi = (url) => {
  baseUrl = url
}

const backendUrl = () => {
  return baseUrl
}

export const callPost = (path, params, onSuccess, onError, headers = {}) => {
  const allHeaders = Object.assign({}, defaultHeaders(), headers)
  return axios.post(`${backendUrl()}/${path}`, params, { headers: allHeaders })
    .then(res => onSuccess(res))
    .catch(error => onError(error))
}

export const callPut = (path, params, onSuccess, onError, headers = {}) => {
  const allHeaders = Object.assign({}, defaultHeaders(), headers)
  return axios.put(`${backendUrl()}/${path}`, params, { headers: allHeaders })
    .then(res => onSuccess(res))
    .catch(error => onError(error))
}

export const callGet = (path, params, onSuccess, onError, headers = {}) => {
  const config = {
    params: params,
    headers: Object.assign({}, defaultHeaders(), headers)
  }
  return axios.get(`${backendUrl()}/${path}`, config)
    .then(res => onSuccess(res))
    .catch(error => onError(error))
}

export const callDelete = (path, onSuccess, onError, headers = {}) => {
  const config = {
    headers: Object.assign({}, defaultHeaders(), headers)
  }
  return axios.delete(`${backendUrl()}/${path}`, config)
    .then(res => onSuccess(res))
    .catch(error => onError(error))
}
