import React, { useState, useEffect } from "react"
import { useSelector } from './store'
import { BrowserRouter, Route, Routes } from "react-router-dom";
import HomeIcon from '@mui/icons-material/HomeOutlined';
import SearchIcon from '@mui/icons-material/Search'
import MoneyIcon from '@mui/icons-material/Money'
import HistoryIcon from '@mui/icons-material/History'
import { Root } from "./Root";
import { Login } from "./components/Login/Login";
import { Signup } from "./components/Signup/Signup";
import { VerifyAccount } from "./components/Signup/VerifyAccount";
import { Subscriptions } from "./components/Subscriptions/Subscriptions";
import { Search } from "./components/Search/Search";
import { Add } from "./components/Add/Add";
import { Budget } from "./components/Budget/Budget";
import { Payments } from "./components/Payments/Payments";
import { DefaultLayout } from "./components/Layouts/DefaultLayout";
import { AuthLayout } from "./components/Layouts/AuthLayout";
import { SimplyButton } from './components/simplyComponents/SimplyButton'

import {
  Snackbar,
  Alert,
  CssBaseline
} from "@mui/material"
import {
  retrieveTokenFromLocalStorage,
  fetchAccount,
  silenceAlert,
  fetchSliders
} from "./lib/actions.js"

const SECTIONS = {
  dashboard: {
    path: "/",
    title: "Dashboard",
    menuIcon: <HomeIcon />
  },
  search: {
    path: "/search",
    title: "Find a nonprofit",
    menuIcon: <SearchIcon />
  },
  budget: {
    path: "/budget",
    title: "Edit Budget",
    menuIcon: <MoneyIcon />
  },
  payments: {
    path: "/payments",
    title: "History",
    menuIcon: <HistoryIcon />
  },
  add: {
    path: "/add",
    title: "Propose a new nonprofit"
  },
  login: {
    path: "/login",
    title: "Login"
  },
  signup: {
    path: "/signup",
    title: "Signup"
  },
  confirmation: {
    path: "/confirmation",
    title: "Confirmation"
  }
}

export const App = () => {
  const notification = useSelector((state) => state.notifications.value)
  const authToken = useSelector((state) => state.authToken.value)
  useEffect(() => {
    retrieveTokenFromLocalStorage()
  }, [])
  const [subtitle, setSubtitle] = useState({})
  useEffect(() => {
    if (authToken) {
      fetchAccount()
      fetchSliders()
    }
  }, [authToken])

  const alertAction = (action) => {
    if (action === "goToDashboard") {
      return (
        <SimplyButton to="/" onClick={silenceAlert} color="inherit" size="small" variant="outlined">
          Go to my dashboard
        </SimplyButton>
      )
    }
  }

  return (
    <BrowserRouter>
      < CssBaseline />
      { notification ? (
        <Snackbar anchorOrigin={{ horizontal: 'center', vertical: 'top' }} open={!!notification} onClose={() => silenceAlert()}>
          <Alert
            onClose={() => silenceAlert()}
            variant="filled"
            severity={notification.severity}
            sx={{ width: '100%' }}
            action={
              notification.action ? alertAction(notification.action) : null
            }
          >
            {notification.text}
          </Alert>
        </Snackbar>
      ) : "" }
      <Routes>
        <Route path="/" element = {
          <Root
            authToken={authToken}
            sections={SECTIONS}
          />
        }>
          <Route index element={
            <DefaultLayout subtitle={subtitle} sections={SECTIONS}>
              <Subscriptions setSubtitle={setSubtitle} />
            </DefaultLayout>
          } />
          <Route path={SECTIONS.search.path} element={
            <DefaultLayout subtitle={subtitle} sections={SECTIONS} >
              <Search setSubtitle={setSubtitle} />
            </DefaultLayout>
          } />
          <Route path={SECTIONS.add.path} element={
            <DefaultLayout subtitle={subtitle} sections={SECTIONS}>
              <Add setSubtitle={setSubtitle} />
            </DefaultLayout>
          } />
          <Route path={SECTIONS.budget.path} element={
            <DefaultLayout subtitle={subtitle} sections={SECTIONS}>
              <Budget setSubtitle={setSubtitle} />
            </DefaultLayout>
          } />
          <Route path={SECTIONS.payments.path} element={
            <DefaultLayout subtitle={subtitle} sections={SECTIONS}>
              <Payments setSubtitle={setSubtitle} />
            </DefaultLayout>
          } />
          <Route path={SECTIONS.login.path} element={
            <AuthLayout>
              <Login />
            </AuthLayout>
          } />
          <Route path={SECTIONS.signup.path} element={
            <AuthLayout>
              <Signup />
            </AuthLayout>
          } />
          <Route path={SECTIONS.confirmation.path} element={
            <AuthLayout>
              <VerifyAccount />
            </AuthLayout>
          } />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}
