import React, { useEffect } from 'react';
import { useSelector } from '../../store'
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import {
  Box,
  Grid,
  Link,
  useMediaQuery
} from '@mui/material';
import { PrettySlider } from './PrettySlider'
import { CustomSliderMoneyValue } from './CustomSliderMoneyValue'
import {
  updateSlider,
  setNewSliderValue
} from "../../lib/actions"

const DEFAULT_ICON = "org_default.png"

const marks = [
  {
    value: 0,
  },
  {
    value: 100,
  },
];

export function CustomSlider({ nonprofit, resort }) {
  const theme = useTheme()
  const darkMode = theme.palette.mode === "dark"
  const slider = useSelector((state) => state.dashboard.sliders.find((sl) => sl.nonprofitUuid === nonprofit.uuid)) || { value: 0 }
  const isTinyScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const setValue = (value) => {
    setNewSliderValue({ uuid: slider.uuid, value })
  }

  const onSubmit = (value) => {
    updateSlider({ uuid: slider.uuid, value })
  }

  useEffect(() => {
    resort()
  }, [slider.value])

  const sliderPosition = slider
    ? ((typeof(slider.newValue) === "undefined")
      ? slider.value
      : slider.newValue)
    : 0;

  const iconPath = nonprofit.iconPath || DEFAULT_ICON

  const icon = (
    nonprofit.iconBackgroundColor ? (
      <Box
        component="img"
        sx={{
          width: isTinyScreen ? 50 : 60,
          height: isTinyScreen ? 50 : 60,
          mr: isTinyScreen ? 2 : 2,
          verticalAlign: "middle",
          objectFit: "contain",
          backgroundColor: nonprofit.iconBackgroundColor
        }}
        alt={nonprofit.name}
        src={iconPath}
      />
    ) : (
      <Box
        component="img"
        sx={{
          width: isTinyScreen ? 50 : 60,
          height: isTinyScreen ? 50 : 60,
          mr: isTinyScreen ? 2 : 2,
          verticalAlign: "middle",
          objectFit: "contain",
          backgroundColor: "white"
        }}
        alt={nonprofit.name}
        src={iconPath}
      />
    )
  )

  const backbroundColorOnHover = darkMode ? "rgba(144, 202, 249, 0.24)" : "rgba(0,0,0,0.03)"

  return (
    <Box sx={{ '&:hover': { backgroundColor: backbroundColorOnHover}, p: 2 }}>
      <Grid container wrap="nowrap">
        <Grid item sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}>
          {icon}
        </Grid>
        <Grid item xs>
          <Grid container direction="row" justifyContent="space-between">
            <Grid item xs>
              <Link target="_blank" sx={{ textDecoration: "none", color: "inherit" }} href={nonprofit.url}>
                <Typography>
                  {nonprofit.name}
                </Typography>
              </Link>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item container xs alignItems="center" sx={{ height: "40px"}}>
              <PrettySlider
                aria-label="pretty slider"
                value={sliderPosition}
                onChange={(ev, newValue) => setValue(newValue)}
                onChangeCommitted={(ev, newValue) => onSubmit(newValue)}
                valueLabelDisplay="auto"
                marks={marks}
              />
            </Grid>
            <CustomSliderMoneyValue slider={slider} />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
}
