import React, { useState } from "react";
import {
  PaymentElement,
  useStripe,
  useElements
} from "@stripe/react-stripe-js";
import {
  Button,
  Card,
  Typography,
  CardContent
} from '@mui/material';
import { frontendUrl } from '../../lib/features';

export function CheckoutForm({ clientSecret }) {
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    const { error } = await stripe.confirmSetup({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: `${frontendUrl()}/budget`,
      },
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, the user will be redirected to
    // `return_url`. For some payment methods like iDEAL, the user will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error.message);
    } else {
      setMessage("An unexpected error occurred.");
    }

    setIsLoading(false);
  };

  return (
    <Card elevation={3}>
      <Typography sx={{ p: 1, textAlign: "center" }}>
       Secure payment with Stripe
      </Typography>
      <CardContent>
        <form id="payment-form" onSubmit={handleSubmit}>
          <PaymentElement id="payment-element" />
          <Button variant="contained" fullWidth sx={{ mt: 2 }} disabled={isLoading || !stripe || !elements} type="submit">
            <span id="button-text">
              Donate
            </span>
          </Button>
          {/* Show any error or success messages */}
          {message && <div id="payment-message">{message}</div>}
        </form>
      </CardContent>
    </Card>
  );
}
