import React, { useState, useEffect } from 'react';
import { useSelector } from '../../store'
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import {
  Box,
  Grid,
  Link,
  useMediaQuery
} from '@mui/material';
import { useSearchParams, useNavigate } from 'react-router-dom'
import { PrettySlider } from "./PrettySlider"
import {
  createSlider,
  updateSlider,
  setNewSliderValue
} from "../../lib/actions"

const DEFAULT_ICON = "org_default.png"

const marks = [
  {
    value: 0,
  },
  {
    value: 100,
  },
];

export function Nonprofit({ nonprofit, widget }) {
  const theme = useTheme()
  const darkMode = theme.palette.mode === "dark"
  const isTinyScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const slider = useSelector((state) => state.dashboard.sliders.find((sl) => sl.nonprofitUuid === nonprofit.uuid))
  const slidersLoaded = useSelector((state) => state.dashboard.fetched)
  const authToken = useSelector((state) => state.authToken.value)
  const navigate = useNavigate();
  const [temporarySliderValue, setTemporarySliderValue] = useState(0)
  useEffect(() => {
    if (slider) {
      setTemporarySliderValue(slider.value)
    }
  }, [slider])

  const setValue = (value) => {
    if (slider) {
      setNewSliderValue({ uuid: slider.uuid, value })
    } else {
      setTemporarySliderValue(value)
    }
  }

  const onSubmit = (value) => {
    if (authToken) {
      if (slider) {
        updateSlider({ uuid: slider.uuid, value })
      } else {
        createSlider({ nonprofitUuid: nonprofit.uuid, value })
      }
    } else {
      navigate(`/signup?value=${value}&nonprofit=${nonprofit.uuid}`)
    }
  }

  const sliderPosition = slider
    ? ((typeof(slider.newValue) === "undefined")
      ? slider.value
      : slider.newValue)
    : temporarySliderValue;

  const iconPath = nonprofit.iconPath || DEFAULT_ICON

  const icon = (
    nonprofit.iconBackgroundColor ? (
      <Box
        component="img"
        sx={{
          width: isTinyScreen ? 50 : 60,
          height: isTinyScreen ? 50 : 60,
          mr: isTinyScreen ? 2 : 2,
          verticalAlign: "middle",
          objectFit: "contain",
          backgroundColor: nonprofit.iconBackgroundColor,
        }}
        alt={nonprofit.name}
        src={iconPath}
      />
    ) : (
      <Box
        component="img"
        sx={{
          width: isTinyScreen ? 50 : 60,
          height: isTinyScreen ? 50 : 60,
          mr: isTinyScreen ? 2 : 2,
          verticalAlign: "middle",
          objectFit: "contain",
          backgroundColor: "white"
        }}
        alt={nonprofit.name}
        src={iconPath}
      />
    )
  )

  const backbroundColorOnHover = darkMode ? "rgba(144, 202, 249, 0.24)" : "rgba(0,0,0,0.03)"

  return (
    <Box sx={{ '&:hover': { backgroundColor: backbroundColorOnHover}, p: isTinyScreen ? 1 : 2 }}>
      <Grid container wrap="nowrap">
        <Grid item sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}>
          {icon}
        </Grid>
        <Grid item xs>
          <Grid container direction="row" justifyContent="space-between">
            <Grid item xs>
              <Link target="_blank" sx={{ textDecoration: "none", color: "inherit" }} href={nonprofit.url} >
                <Typography>
                  {nonprofit.name}
                </Typography>
              </Link>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item container xs alignItems="center" sx={{ height: "40px", mr: 0}}>
              <PrettySlider
                aria-label="pretty slider"
                value={sliderPosition}
                onChange={(ev, newValue) => setValue(newValue)}
                onChangeCommitted={(ev, newValue) => onSubmit(newValue)}
                valueLabelDisplay="auto"
                marks={marks}
              />
            </Grid>
          </Grid>
          { (slider && (slider.value > 0)) ? (
            <Grid container justifyContent="flex-end" alignItems="flex-start" sx={{height: 16, mb: 1}}>
              <Grid item style={{display: "grid"}}>
                <Typography sx={{lineHeight: 0.8, fontSize: 12, textAlign: "right", opacity: "0.5" }} variant="caption">
                  You're already supporting <Box component="span" sx={{display: widget ? "inline" : "none"}}>on{'\u00A0'}</Box>
                </Typography>
              </Grid>
              <Grid item sx={{display: widget ? "block" : "none"}}>
                <Link href="https://app.cordialcredit.org" target="_blank" style={{ textDecoration: "none", color: "inherit"}}>
                  <Typography sx={{lineHeight: 0.8, fontSize: "12px!important", textAlign: "right"}}>
                    cordial<br/>
                    credit
                  </Typography>
                </Link>
              </Grid>
            </Grid>
          ) : (
            <Grid container justifyContent="flex-end" alignItems="flex-start" sx={{height: 16, display: widget ? "flex" : "none" , mb: 1}}>
              <Grid item style={{display: "grid"}}>
                <Typography sx={{lineHeight: 0.8, fontSize: 12, textAlign: "right", opacity: "0.5" }} variant="caption">
                  Move the slider to support with{'\u00A0'}
                </Typography>
              </Grid>
              <Grid item>
                <Link href="https://app.cordialcredit.org" target="_blank" style={{ textDecoration: "none", color: "inherit"}}>
                  <Typography sx={{lineHeight: 0.8, fontSize: "12px!important", textAlign: "right"}}>
                    cordial<br/>
                    credit
                  </Typography>
                </Link>
              </Grid>
            </Grid>
          ) }
        </Grid>
      </Grid>
      <Grid sx={{ marginLeft: isTinyScreen ? "50px" : "60px", paddingLeft: "16px", paddingTop: "4px", paddingBottom: "4px" }}>
        <Typography variant="body2">
          {nonprofit.description}
        </Typography>
      </Grid>
    </Box>
  )
}
