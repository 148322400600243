import { createSlice } from '@reduxjs/toolkit'
import {
  calculateRates,
  calculateActualBudgetInCents
} from './lib/rates'

const notifications = createSlice({
  name: 'notifications',
  initialState: {
    value: false
  },
  reducers: {
    set: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.value = action.payload
    },
    reset: (state) => {
      state.value = false
    }
  },
})

const authToken = createSlice({
  name: 'authToken',
  initialState: {
    /*
      false - not set yet
      null/undefined - logged out
      string - logged in
    */
    value: false,
  },
  reducers: {
    set: (state, action) => {
      state.value = action.payload
    },
    reset: (state) => {
      state.value = null
    }
  },
})

const dashboard = createSlice({
  name: 'dashboard',
  initialState: {
    fetched: false,
    nonprofits: [],
    proposals: [],
    sliders: [],
    budget: 0.0,
    actualBudget: 0,
    rates: []
  },
  reducers: {
    set: (state, action) => {
      state.nonprofits = action.payload.nonprofits
      state.proposals = action.payload.proposals
      const sliders = action.payload.sliders
      const budget = action.payload.budget
      state.sliders = sliders
      state.budget = budget
      state.actualBudget = calculateActualBudgetInCents(budget)
      state.rates = calculateRates(sliders, budget)
      state.fetched = true
      if (action.payload.nextPaymentDate) {
        state.nextPaymentDate = action.payload.nextPaymentDate
      }
    },
    setNewValue: (state, action) => {
      const uuid = action.payload.uuid
      const value = action.payload.value
      const slider = state.sliders.find((slider) => slider.uuid === uuid)
      slider.newValue = value
      state.rates = calculateRates(state.sliders, state.budget)
    },
    updateBudget: (state, action) => {
      const budget = action.payload
      state.budget = budget
      state.rates = calculateRates(state.sliders, budget)
    }
  },
})

const account = createSlice({
  name: 'account',
  initialState: {
    value: {}
  },
  reducers: {
    set: (state, action) => {
      state.value = action.payload
    },
    setAlmostActive: (state, action) => {
      if (state.value.subscriptionStatus === "budget_set") {
        state.value.subscriptionStatus = "almost_active"
      }
    }
  }
})

const payments = createSlice({
  name: 'payments',
  initialState: {
    fetched: false,
    items: []
  },
  reducers: {
    set: (state, action) => {
      state.items = action.payload
      state.fetched = true
    }
  }
})

export const slices = {
  notifications,
  authToken,
  dashboard,
  account,
  payments
}
