import React, { useState, useEffect } from 'react';
import { useSearchParams } from "react-router-dom";
import CssBaseline from '@mui/material/CssBaseline';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { createTheme, responsiveFontSizes, ThemeProvider } from '@mui/material/styles';
import {
  fetchNonprofit
} from "../../lib/actions.js"

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://www.cordialcredit.org/">
        Cordial Credit
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

let theme = createTheme({
  typography: {
    fontSize: 18,
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      "Segoe UI",
      "Roboto",
      "Helvetica",
      "Arial",
      "sans-serif"
    ]
  }
});

theme = responsiveFontSizes(theme);

export function AuthLayout({ children }) {
  const [searchParams, setSearchParams] = useSearchParams()
  const [nonprofit, setNonprofit] = useState("")

  useEffect(() => {
    const value = searchParams.get('value')
    const nonprofit = searchParams.get('nonprofit')
    if (value && nonprofit) {
      fetchNonprofit({ nonprofitId: nonprofit, setNonprofit })
    }
  }, [searchParams])

  const icon = nonprofit ? (
    nonprofit.iconBackgroundColor ? (
      <Box
        component="img"
        sx={{
          width: 60,
          height: 60,
          verticalAlign: "middle",
          objectFit: "contain",
          backgroundColor: nonprofit.iconBackgroundColor,
        }}
        alt={nonprofit.name}
        src={nonprofit.iconPath}
      />
    ) : (
      <Box
        component="img"
        sx={{
          width: 60,
          height: 60,
          verticalAlign: "middle",
          objectFit: "contain",
          backgroundColor: "white"
        }}
        alt={nonprofit.name}
        src={nonprofit.iconPath}
      />
    )
  ) : ''
  const darkMode = theme.palette.mode === "dark"
  const backbroundColorOnHover = darkMode ? "rgba(144, 202, 249, 0.24)" : "rgba(0,0,0,0.03)"

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ backgroundImage: "url('background.png')", backgroundSize: "cover", backgroundPosition: "center bottom", backgroundAttachment: "fixed", paddingTop: 1, paddingBottom: 50, position: "relative", width: "100%", height: "100%"}}>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Paper>
          { nonprofit ? (
            <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              padding: 3,
              marginBottom: -13,
              textAlign: 'center'
            }}
          >
            <Typography>
              You just added a nonprofit to your support list.
              Create an account to save that information and add more.
            </Typography>

            <Box sx={{ '&:hover': { backgroundColor: backbroundColorOnHover}, p: 2 }}>
              <Grid item sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                mb: 1
              }}>
                {icon}
              </Grid>
              <Grid item xs>
                <Grid container direction="row" justifyContent="space-between">
                  <Grid item xs>
                    <Link target="_blank" sx={{ textDecoration: "none", color: "inherit" }} href={nonprofit.url} >
                      <Typography>
                        {nonprofit.name}
                      </Typography>
                    </Link>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Box>
          ) : ''}
            { children }
          </Paper>
          <Paper>
            <Copyright sx={{ mt: 5, pt: 2, pb: 2 }} />
          </Paper>
        </Container>
      </Box>
    </ThemeProvider>
  );
}
