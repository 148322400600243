import { backendUrl } from "./features"
import { callPost, callPut, callGet, callDelete, setupApi } from "./simplyLib/simplyApis"

setupApi(backendUrl())

export const callSignup = (params, onSuccess, onError) => {
  return callPost('auth/v1/signup.json', params, onSuccess, onError);
}

export const callLogin = (params, onSuccess, onError) => {
  return callPost('auth/v1/login.json', params, onSuccess, onError);
}

export const callLogout = (authToken, onSuccess, onError) => {
  const headers = {
    'Authorization': authToken
  }
  return callDelete('auth/v1/logout.json', onSuccess, onError, headers)
}

export const callConfirmToken = (params, onSuccess, onError) => {
  return callGet("auth/v1/confirmation.json", params, onSuccess, onError)
}

export const callRegenerateToken = (params, onSuccess, onError) => {
  return callPost("auth/v1/confirmation.json", params, onSuccess, onError)
}

export const callAccountGet = (authToken, params, onSuccess, onError) => {
  const headers = {
    'Authorization': authToken
  }
  return callGet('app/v1/account.json', params, onSuccess, onError, headers);
}

export const callNonprofitsGet = (params, onSuccess, onError) => {
  return callGet('app/v1/nonprofits.json', params, onSuccess, onError, {});
}

export const callNonprofitGet = (params, onSuccess, onError) => {
  return callGet(`app/v1/nonprofits/${params.id}.json`, {}, onSuccess, onError, {});
}

export const callSlidersGet = (authToken, params, onSuccess, onError) => {
  const headers = {
    'Authorization': authToken
  }
  return callGet('app/v1/sliders.json', params, onSuccess, onError, headers);
}

export const callPaymentsGet = (authToken, params, onSuccess, onError) => {
  const headers = {
    'Authorization': authToken
  }
  return callGet('app/v1/payments.json', params, onSuccess, onError, headers);
}

export const callSliderPost = (authToken, params, onSuccess, onError) => {
  const headers = {
    'Authorization': authToken
  }
  return callPost(`app/v1/sliders.json`, params, onSuccess, onError, headers);
}

export const callSliderPut = (authToken, uuid, params, onSuccess, onError) => {
  const headers = {
    'Authorization': authToken
  }
  return callPut(`app/v1/sliders/${uuid}.json`, params, onSuccess, onError, headers);
}

export const callSliderDelete = (authToken, uuid, onSuccess, onError) => {
  const headers = {
    'Authorization': authToken
  }
  return callDelete(`app/v1/sliders/${uuid}.json`, onSuccess, onError, headers);
}

export const callProposalPost = (authToken, params, onSuccess, onError) => {
  const headers = {
    'Authorization': authToken
  }
  return callPost(`app/v1/proposals.json`, params, onSuccess, onError, headers);
}

export const callBudgetPost = (authToken, params, onSuccess, onError) => {
  const headers = {
    'Authorization': authToken
  }
  return callPost(`app/v1/budget.json`, params, onSuccess, onError, headers);
}

export const callBudgetPut = (authToken, params, onSuccess, onError) => {
  const headers = {
    'Authorization': authToken
  }
  return callPut(`app/v1/budget.json`, params, onSuccess, onError, headers);
}

export const callCheckoutData = (authToken, params, onSuccess, onError) => {
  const headers = {
    'Authorization': authToken
  }
  return callPost('app/v1/checkout.json', params, onSuccess, onError, headers);
}

export const callBillingData = (authToken, params, onSuccess, onError) => {
  const headers = {
    'Authorization': authToken
  }
  return callGet('app/v1/billing.json', params, onSuccess, onError, headers);
}

export const callPaymentConfirmationPendingPost = (authToken, params, onSuccess, onError) => {
  const headers = {
    'Authorization': authToken
  }
  return callPost('app/v1/payment_confirmation_pending.json', params, onSuccess, onError, headers);
}

export const callSubscribePost = (params, onSuccess, onError) => {
  const headers = {
    'Authorization': null
  }
  return callPost(`app/v1/subscribe.json`, params, onSuccess, onError, headers);
}
