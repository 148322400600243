import React, { useState, useEffect } from 'react';
import {
  Grid,
  Typography,
  FormControl,
  Box,
  TextField,
  Chip,
  Stack,
  Alert
} from '@mui/material';
import { useSearchParams } from 'react-router-dom'
import { useSelector } from '../../store'
import { SimplyButton } from '../simplyComponents/SimplyButton'
import { SimplyIconButton } from '../simplyComponents/SimplyIconButton'
import { Nonprofit } from '../shared/Nonprofit';
import SearchIcon from '@mui/icons-material/Search'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import {
  fetchNonprofits
} from "../../lib/actions"

export function Search({ setSubtitle }) {
  const [search, setSearch] = useState("")
  const [nonprofits, setNonprofits] = useState([])
  const [searchParams, setSearchParams] = useSearchParams()
  const authToken = useSelector((state) => state.authToken.value )
  const tags = searchParams.get("tags")
  const loggedOut = !authToken && (authToken !== false)

  useEffect(() => {
    setSubtitle({ search: `${nonprofits.length} nonprofits` })
  }, [nonprofits.length])

  const handleSearchChange = (ev) => {
    setSearch(ev.target.value)
  }

  const withoutTag = (params, tag) => {
    const tags = params.get('tags')
    const newTags = tags.split(',').filter((t) => t !== tag)
    if (newTags.length > 0) {
      params.set('tags', newTags)
    } else {
      params.delete('tags')
    }
    return params
  }

  const handleDeleteTag = (tag) => {
    setSearchParams((params) => withoutTag(params, tag))
  }

  useEffect(() => {
    const timout = setTimeout(() => {
      fetchNonprofits({ setNonprofits, search, tags })
    }, 500)
    return () => clearTimeout(timout)
  }, [search, tags])

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Grid container justifyContent="space-between" alignItems="flex-end" sx={{mb: 1, mt: 1, px: 2}}>
          <Grid item>
            <FormControl variant="standard">
              <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                <SearchIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                <TextField id="input-with-sx" label="Search" variant="standard" value={search} onChange={handleSearchChange} />
              </Box>
            </FormControl>
          </Grid>
          <Grid item>
            <Typography component="span" sx={{ color: "text.disabled", display: { xs: "none", lg: "inline" }}}>
              You didn't find what you're looking for?
            </Typography>

            <SimplyButton to="/add" variant="outlined" size="small" style={{ marginLeft: 8 }} sx={{ display: { xs: "none", sm: "inline-flex" }}}>
              Propose a new one
            </SimplyButton>
            <SimplyIconButton to="/add" aria-label="add" color="primary" sx={{ display: { xs: "inline-flex", sm: "none" }}}>
              <AddCircleOutlineIcon />
            </SimplyIconButton>
          </Grid>
        </Grid>
        { loggedOut ? (
          <Grid container justifyContent="space-between" sx={{mb: 3, mt: 3, px: 2}}>
            <Alert variant="filled" severity="info" sx={{ width: "100%", fontWeight: 'bold' }}>
              Start creating your support list by picking a non-profit and moving the slider!
            </Alert>
          </Grid>
        ) : ''}
        { tags ? (
          <Grid container justifyContent="space-between" sx={{mb: 3, mt: 3, px: 2}}>
            <Stack direction="row" spacing={1}>
              { tags.split(',').map((tag) => (
                <Chip key={tag} label={tag} color="primary" variant="outlined" onDelete={() => handleDeleteTag(tag)}/>
              ))}
            </Stack>
          </Grid>
        ) : "" }
        { nonprofits.map((nonprofit) => <Nonprofit
          key={nonprofit.uuid}
          nonprofit={nonprofit}
        />)}
      </Grid>
    </Grid>
  )
}
