import React, { useEffect } from "react"
import Table from '@mui/material/Table';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import { SimplyButton } from '../simplyComponents/SimplyButton'
import {
  Paper
} from '@mui/material';
import Moment from 'react-moment';
import { CustomMiniSlider } from '../shared/CustomMiniSlider';
import { useSelector } from '../../store'
import { fetchPayments } from "../../lib/actions.js"
import { centsAsCurrency } from '../../lib/utils'

export function Payments() {
  const authToken = useSelector((state) => state.authToken.value)
  const payments = useSelector((state) => state.payments.items)
  const fetched = useSelector((state) => state.payments.fetched)
  useEffect(() => {
    if (authToken) {
      fetchPayments()
    }
  }, [authToken])

  if (!fetched) {
    return ""
  }

  const alert = (
    <Grid item xs={12}>
      <Alert severity="info">
        We forward the donations as soon as we receive the funds
        on our bank account. As we're working with a payment provider (Stripe)
        and bank (GLS Bank), we depend on their processing speed.
        Usually, this should be the case within 3 days,
        but sometimes it can take up to 30 days.
      </Alert>
    </Grid>
  )

  return (
    <Grid container spacing={3} sx={{mb: 1, px: 2 }}>
      { (payments.length === 0) ? (
        <>
          <Grid item xs>
            <Alert severity="info" sx={{ mb: 1 }}>
              Once we process your first monthly donation, you will see here the history of all your past donations.
            </Alert>
          </Grid>
          {alert}
        </>
      ) : (
        <>
          {alert}
          <Grid item xs>
            <TableContainer component={Paper}>
              <Table aria-label="donation history">
                <TableHead>
                  <TableRow>
                    <TableCell align="left">When</TableCell>
                    <TableCell align="left">Orgs</TableCell>
                    <TableCell align="left">Donation</TableCell>
                    <TableCell align="left">Fee</TableCell>
                    <TableCell align="left">Distributed</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {payments.map((payment) => (
                    <TableRow
                      key={`${payment.name}-${payment.createdAt}`}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        <Moment format="MMMM Do YYYY">{payment.createdAt}</Moment>
                      </TableCell>
                      <TableCell align="left">{payment.donations.length}</TableCell>
                      <TableCell align="left">{centsAsCurrency(payment.amount)}</TableCell>
                      <TableCell align="left">{centsAsCurrency(payment.fee)}</TableCell>
                      <TableCell align="left">{centsAsCurrency(payment.actualAmount)}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </>
      )}
    </Grid>
  );
}
